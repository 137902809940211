<template>
    <div class="my-account-head-info">
        <div class="container">
            <div class="head-info-flex d-flex align-items-center">
                <div class="avatar">
                    <label
                        class="
                            upload-avatar
                            d-flex
                            justify-content-center
                            align-items-center
                            bg-white
                            text-brand
                        "
                    >
                        +
                        <input
                            @change="handleAvatar"
                            type="file"
                            ref="avatar"
                            class="d-none"
                        />
                    </label>
                    <radial-progress-bar
                        id="account"
                        :diameter="diameter"
                        :completed-steps="completedSteps"
                        :total-steps="10"
                        startColor="#007EC6"
                        stopColor="#007EC6"
                        :strokeWidth="strokeWidth"
                        :innerStrokeWidth="innerStrokeWidth"
                        innerStrokeColor="#f5f5f5"
                    >
                        <img
                            v-if="user.images"
                            :src="user.images"
                            :alt="[
                                user.first_name ? user.first_name : 'avatar',
                            ]"
                        />
                        <img
                            v-else
                            src="@/assets/imgs/avatar-lg.png"
                            :alt="[
                                user.first_name ? user.first_name : 'avatar',
                            ]"
                        />
                    </radial-progress-bar>
                </div>
                <div class="mx-4">
                    <h2 class="text-white mb-1">
                        {{ user.first_name || user.email }}
                    </h2>
                    <h5 v-if="user.mobile" class="text-white">
                        {{ user.mobile }}
                    </h5>
                    <small v-else class="text-white">{{
                        $t("noMobileRegistered")
                    }}</small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RadialProgressBar from "vue-radial-progress";

import { getUser } from "@/API/auth"
import config from "@/config";
import axios from "axios";

export default {
    name: "AccountHeadInfo",
    components: {
        RadialProgressBar,
    },
    data() {
        return {
            avatar: null,
            diameter: 130,
            completedSteps: 0,
            counter: null,
            strokeWidth: 5,
            innerStrokeWidth: 5,
        };
    },
    computed: {
        user() {
            return this.$store.state.auth.user || {};
        },
    },
    methods: {
        handleAvatar() {
            this.avatar = this.$refs?.avatar?.files[0];

            if (!this.avatar) {
                return;
            }

            this.updateCounter("start");

            let formData = new FormData();
            formData.append("image", this.avatar);

            axios
                .post(`${config.baseURL}/update-image`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${this.$store.getters["auth/token"]}`,
                    },
                })
                .then((res) => {
                    // console.log(res.data);
                    this.$notify(res.data.message);

                    getUser().then(res => {
                        if (res?.status == 200) {
                            const user = res.data?.data;
                            // console.log(user);
                            this.$store.commit("auth/UPDATE_USER_INFO", user);
                        }
                    });
                })
                .catch((error) => {
                    console.log(error.response.data.message);
                    if (error?.response?.status == 500) {
                        this.$router.push("/500");
                        return;
                    }
                    this.$notify(error.response.data.message);
                })
                .finally(() => {
                    this.avatar = null;
                    this.updateCounter();
                });
        },
        updateCounter(type) {
            if (type == "start") {
                this.counter = setInterval(() => {
                    let value = 0;
                    if (value == 10) {
                        value = 0;
                    } else {
                        value++;
                    }
                    this.completedSteps = value;
                }, 150);
            } else {
                clearInterval(this.counter);
                this.completedSteps = 10;
            }
        },
    },
};
</script>
